/* eslint-disable react-hooks/exhaustive-deps */
import { NumberHelper } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { formatPlate, formatVehicle } from "../../../helper";
import { useProductsTradein, useSkipFirstEffect, useTradeinContext } from "../../../hooks";
import {
  GetPaginatedTradeInRequest,
  IGetPaginatedTradeInRequest,
  IGetPaginatedTradeInResponse,
  IGetTradeInPricesResponseModel,
  IPagedResponseBase,
} from "../../../models";
import { SkeletonLoading } from "../../atoms";
import {
  HeaderDealerMolecule,
  PaginationControlMolecule,
  PriceInformationMolecule,
  StatusInformationMolecule,
  VehicleInformationMolecule,
} from "../../molecules";

import { ContainerCardLead, EmptyList, Wrapper } from "./list-leads.organisms.style";

const pageSize = 5;

type tradeInListType = Array<
  IGetPaginatedTradeInResponse & {
    photos: Array<{ imageId: number; url: string }>;
  } & { prices: IGetTradeInPricesResponseModel | null }
>;

export const ListLeadsOrganisms: React.FC = () => {
  const { getPaginatedTradeIn, getUrlsPicture, getTradeInPrices } = useProductsTradein();
  const { userContext } = useTradeinContext();

  const [paginationData, setPaginationData] = useState<IPagedResponseBase>();
  const [pageNumber, setPageNumber] = useState<{
    number: number;
    isFilter: boolean;
  }>();
  const [tradeInList, setTradeInList] = useState<tradeInListType>([]);
  const [skeletonList, setSkeletonList] = useState([...Array(pageSize)]);
  const [filterValues, setFilterValues] = useState<IGetPaginatedTradeInRequest>();

  const handlePageNumberChange = (number: number, isFilter: boolean = false) => {
    setPageNumber({
      number,
      isFilter,
    });
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  async function getTradeInList(data: IGetPaginatedTradeInResponse[]) {
    scrollToTop();
    setTradeInList([]);
    setSkeletonList([...Array(data.length)]);
    for (let i = 0; i < data.length; i++) {
      const photos = getUrlsPicture(data[i].tradeInId);
      const prices = getTradeInPrices(data[i].tradeInId);

      const extraInfo = await Promise.all([photos, prices]);
      setTradeInList(prev => [
        ...prev,
        {
          ...data[i],
          photos: extraInfo[0],
          prices: extraInfo[1],
        },
      ]);
      setSkeletonList([...Array(data.length - (i + 1))]);
    }
  }

  useSkipFirstEffect(() => {
    async function firstRender() {
      if (userContext) {
        const initialValues = {
          pageSize,
          page: 1,
          startDate: "",
          finalDate: "",
          tradeInProgress: null,
          search: "",
        };

        const { data, ...paginationData } = await getPaginatedTradeIn(initialValues);
        getTradeInList(data);
        setFilterValues(initialValues);
        setPaginationData(paginationData);
      }
    }
    firstRender();
  }, [userContext]);

  useEffect(() => {
    const validationFilter = async () => {
      if (filterValues && pageNumber && !pageNumber.isFilter) {
        const request = new GetPaginatedTradeInRequest({
          ...filterValues,
          page: pageNumber.number,
        });

        const { data, ...paginationData } = await getPaginatedTradeIn(request);
        getTradeInList(data);
        setPaginationData(paginationData);
      }
    };
    validationFilter();
  }, [pageNumber]);

  if (!userContext) {
    return null;
  }

  return (
    <Container>
      <HeaderDealerMolecule
        setPaginationData={setPaginationData}
        setFilterValues={values => setFilterValues(values)}
        setPageNumber={handlePageNumberChange}
        getTradeInList={getTradeInList}
        pageSize={pageSize}
        isLoading={skeletonList.length !== 0}
        hasFilters={true}
      />

      <Wrapper>
        {tradeInList.length === 0 && skeletonList.length === 0 ? (
          <EmptyList>Nenhum registro encontrado</EmptyList>
        ) : (
          tradeInList.map(
            ({
              tradeInId,
              tradeInVehicle,
              customer,
              photos,
              prices,
              purchaseVehicle,
              negotiatedValue,
              tradeInProgress,
              addedIn,
            }) => (
              <ContainerCardLead key={tradeInId}>
                <VehicleInformationMolecule
                  photos={photos}
                  tradeInVehicle={tradeInVehicle}
                  customer={customer}
                  dealershipName={userContext.dealership.name}
                  purchaseVehicle={purchaseVehicle}
                />
                <PriceInformationMolecule
                  tradeInPrices={prices}
                  negotiatedValueChecklist={negotiatedValue}
                />
                <StatusInformationMolecule
                  tradeInId={tradeInId}
                  tradeInProgress={tradeInProgress}
                  proposalId={tradeInId}
                  addedIn={addedIn}
                  carDescription={formatVehicle(
                    tradeInVehicle.brandName,
                    tradeInVehicle.modelName,
                    tradeInVehicle.versionName,
                    tradeInVehicle.hp,
                    tradeInVehicle.modelYear,
                  ).toUpperCase()}
                  carPrice={NumberHelper.toFormatString(
                    tradeInVehicle.requestedPrice,
                    0,
                    "--",
                  )}
                  plate={formatPlate(tradeInVehicle.plate)}
                />
              </ContainerCardLead>
            ),
          )
        )}
        {skeletonList.map((_, idx) => (
          <SkeletonLoading key={idx} type="cardListLeads" />
        ))}
        {paginationData &&
          skeletonList.length === 0 &&
          paginationData.totalPages > 1 &&
          tradeInList.length > 0 && (
            <PaginationControlMolecule
              paginationData={paginationData}
              setPageNumber={handlePageNumberChange}
            />
          )}
      </Wrapper>
    </Container>
  );
};
