import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { ButtonPrimary, DateHelper } from "c4u-web-components";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { paths } from "../../../constants";
import { formatDate, TradeinStatusHelper } from "../../../helper";
import { useProductsTradein } from "../../../hooks";
import { ITradeInProgressHistoryResponse, TradeInProgress } from "../../../models";
import { Calendar, IconCheck } from "../../assets";
import { LoadingSpinner, Show } from "../../atoms";

import {
  AddedDate,
  Body,
  BoxCarInformation,
  CarInformartion,
  ChangeDateInformation,
  ChangeInformation,
  DetailsOfThatProposal,
  EmptyList,
  IconCalendar,
  Identifier,
  Information,
  InformationHeaderList,
  List,
  NegotiationStatus,
  ProposalIdentifier,
  SeeHistorical,
  StatusInformation,
  StyledModal,
  TitleModal,
} from "./status-information.molecule.style";

type PriceInformationMoleculeTypes = {
  tradeInId: number;
  tradeInProgress: TradeInProgress;
  proposalId: number;
  addedIn: string;
  carDescription: string;
  plate: string;
  carPrice: string;
};

export const StatusInformationMolecule = ({
  tradeInId,
  tradeInProgress,
  proposalId,
  addedIn,
  carDescription,
  plate,
  carPrice,
}: PriceInformationMoleculeTypes) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { getTradeInProgressHistory } = useProductsTradein();
  const [openModal, setOpenModal] = useState(false);
  const [tradeInProgressHistory, setTradeInProgressHistory] = useState<
    ITradeInProgressHistoryResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleButtonClick = async () => {
    history.push(`${paths.checkList()}/${tradeInId}`);
  };

  const handleTradeInProgressHistory = async () => {
    try {
      const data = await getTradeInProgressHistory(tradeInId);
      setTradeInProgressHistory(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleHistory = () => {
    setOpenModal(true);
    handleTradeInProgressHistory();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const colors = [
    { backgroundColor: "#ffecdb", borderColor: "#eea200" },
    { backgroundColor: "#f5faf7", borderColor: "#8fd17e" },
    { backgroundColor: "#DEEBE3", borderColor: "#5bc940" },
    { backgroundColor: "#ffe7e7", borderColor: "#eb440c" },
    { backgroundColor: "#ffe7e7", borderColor: "#eb440c" },
    { backgroundColor: "#ffecdb", borderColor: "#eea200" },
    { backgroundColor: "#ffe7e7", borderColor: "#eb440c" },
    { backgroundColor: "#DEEBE3", borderColor: "#5bc940" },
  ];

  return (
    <>
      <StatusInformation>
        <ProposalIdentifier>
          <span>
            <b>{t("ProposalId")}</b> {proposalId}
          </span>
        </ProposalIdentifier>
        <AddedDate>
          <IconCalendar src={Calendar}></IconCalendar>
          {t("AddedIn")}
          <strong>{formatDate(addedIn)}</strong>
        </AddedDate>
        <NegotiationStatus
          backgroundColor={
            colors[tradeInProgress] ? colors[tradeInProgress].backgroundColor : ""
          }
          borderColor={colors[tradeInProgress] ? colors[tradeInProgress].borderColor : ""}
        >
          <div></div>
          <span>{t(TradeInProgress[tradeInProgress]).toUpperCase()}</span>
        </NegotiationStatus>
        <ButtonPrimary profile="manheim" onClick={handleButtonClick}>
          Ver Detalhes
        </ButtonPrimary>
        <SeeHistorical onClick={handleHistory}>{t("SeeHistory")}</SeeHistorical>
      </StatusInformation>

      <StyledModal show={openModal} onHide={handleCloseModal} size="lg">
        <Modal.Body>
          <Body>
            <TitleModal>
              <span>{t("History")}</span>
            </TitleModal>
            <div>
              <span>{carDescription}</span>
            </div>
          </Body>
          <div>
            <IconButton aria-label="close" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </Modal.Body>
        <BoxCarInformation>
          <CarInformartion>
            <Information>
              <span>{t("Plate")}</span>
              <span>{plate}</span>
            </Information>
          </CarInformartion>
          <DetailsOfThatProposal>
            <Identifier>
              <span>
                <b>{t("ProposalId")}</b>
                {tradeInId}
              </span>
            </Identifier>
            <AddedDate>
              <IconCalendar src={Calendar}></IconCalendar>
              {t("AddedIn")}
              <strong>{formatDate(addedIn)}</strong>
            </AddedDate>
          </DetailsOfThatProposal>
        </BoxCarInformation>
        <Show when={!isLoading} fallback={<LoadingSpinner />}>
          <List>
            {tradeInProgressHistory.length === 0 ? (
              <EmptyList>Nenhum registro encontrado</EmptyList>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <InformationHeaderList scope="col"></InformationHeaderList>
                    <InformationHeaderList scope="col">{t("Changes")}</InformationHeaderList>
                    <InformationHeaderList scope="col">
                      {t("DateOfChanges")}
                    </InformationHeaderList>
                  </tr>
                </thead>
                <tbody>
                  {tradeInProgressHistory.map(({ tradeInProgress, updatedAt }) => (
                    <tr key={tradeInProgress}>
                      <th scope="row">
                        <img src={IconCheck} alt="" />
                      </th>
                      <ChangeInformation>
                        {TradeinStatusHelper.getStatusName(tradeInProgress, t)}
                      </ChangeInformation>
                      <ChangeDateInformation>
                        {DateHelper.dateHourCustomFormat(updatedAt, "dd/MM/yyyy HH:mm")}
                      </ChangeDateInformation>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </List>
        </Show>
      </StyledModal>
    </>
  );
};
