import React from "react";
import {
  ContainerCarousel,
  ContainerIcon,
  IconFrame,
  ImageCar,
  ImageIcon,
  VideoContainer,
} from "./carousel-of-car-photos.atom.style";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { VideoFormat } from "../../../helper";
import { CarIconList } from "../../assets";
import { PlayArrow } from "@mui/icons-material";

interface IProps {
  photos: Array<{ imageId: number; url: string }>;
}

export const CarouselOfCarPhotosAtom: React.FC<IProps> = ({ photos }) => {
  return (
    <ContainerCarousel>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView={1}
        navigation
        style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}
      >
        {!photos.length ? (
          <ContainerIcon>
            <ImageIcon src={CarIconList} alt="" />
          </ContainerIcon>
        ) : (
          photos.map((car) => {
            const isVideo = VideoFormat(car.url);
            return (
              <SwiperSlide key={car.imageId} color="#fff">
                {isVideo ? (
                  <VideoContainer>
                    <IconFrame>
                      <PlayArrow htmlColor="#fff" fontSize="large" />
                    </IconFrame>
                    <video width={487} height={275} style={{objectFit: 'cover'}} >
                      <source src={car.url} type="video/webm"></source>
                    </video>
                  </VideoContainer>
                ) : (
                  <ImageCar src={car.url} alt="" />
                )}
              </SwiperSlide>
            );
          })
        )}
      </Swiper>
    </ContainerCarousel>
  );
};
