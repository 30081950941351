import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { PlayArrow } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Plyr from "react-plyr";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { VideoFormat } from "../../../helper";
import { useChecklistContext, useProductsTradein } from "../../../hooks";
import { CarIconList } from "../../assets";
// import { ShareButton } from "../../molecules/share-button/share-button.molecule";

import {
  AdditionalVehicleInformation,
  BasicVehicleInformation,
  ContainerCarousel,
  ContainerIcon,
  IconFrame,
  ImageCar,
  ImageIcon,
  StyledModal,
  VideoContainer,
} from "./checklist-carousel-element.atom.style";

interface IProps {
  brandName: string;
  modelName: string;
  versionName: string;
  hp: number;
  modelYear: number;
}

export const ChecklistCarousel: React.FC<IProps> = ({
  brandName,
  modelName,
  versionName,
  hp,
  modelYear,
}) => {
  const { getUrlsPicture } = useProductsTradein();
  const { tradeInId } = useChecklistContext();

  const [photos, setPhotos] = useState<Array<{ imageId: number; url: string }>>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const basicVehicleInformation = () => {
    return `${brandName} ${modelName}`;
  };

  const additionalVehicleInformation = () => {
    return `${versionName} ${hp} CV`;
  };

  const photosVehicle = useCallback(async () => {
    await getUrlsPicture(tradeInId).then(url => {
      setPhotos(url);
    });
  }, [getUrlsPicture, tradeInId]);

  useEffect(() => {
    photosVehicle();

    const intervalId = setInterval(() => {
      photosVehicle();
    }, 180000);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = (index: number) => {
    setSelectedIndex(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedIndex(null);
  };

  return (
    <>
      <ContainerCarousel>
        {/* {!photos.length ? null:
          <div>
            <ShareButton tradeInId={tradeInId} />
          </div>
          } */}
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView={1}
          navigation
        >
          {!photos.length ? (
            <ContainerIcon>
              <ImageIcon src={CarIconList} alt="" />
            </ContainerIcon>
          ) : (
            photos.map((car, index) => {
              const isVideo = VideoFormat(car.url);
              return (
                <SwiperSlide
                  key={car.imageId}
                  color="#fff"
                  style={{ marginBottom: "0", paddingBottom: "0", cursor: "pointer" }}
                >
                  {isVideo ? (
                    <VideoContainer>
                      <IconFrame>
                        <PlayArrow htmlColor="#fff" fontSize="large" />
                      </IconFrame>
                      <video onClick={() => handleOpenModal(index)}>
                        <source src={car.url} type="video/webm"></source>
                      </video>
                    </VideoContainer>
                  ) : (
                    <ImageCar onClick={() => handleOpenModal(index)} src={car.url} alt="" />
                  )}
                </SwiperSlide>
              );
            })
          )}
        </Swiper>
      </ContainerCarousel>

      <StyledModal show={openModal} onHide={handleCloseModal} size="xl">
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "flex-end",
            borderBottom: "none",
            marginBottom: "0",
          }}
        >
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <BasicVehicleInformation>{basicVehicleInformation()}</BasicVehicleInformation>
        <AdditionalVehicleInformation>
          {additionalVehicleInformation()}
        </AdditionalVehicleInformation>
        <Modal.Body>
          {selectedIndex !== null && (
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              navigation
              initialSlide={selectedIndex}
              style={{ height: "auto", width: "100%" }}
            >
              {photos.map(car => {
                const isVideo = VideoFormat(car.url);
                return (
                  <SwiperSlide
                    key={car.imageId}
                    color="#fff"
                    style={{ height: "100%", width: "100%" }}
                  >
                    {isVideo ? (
                      <Plyr type="video" url={car.url} />
                    ) : (
                      <img src={car.url} alt="" />
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </Modal.Body>
      </StyledModal>
    </>
  );
};
